"use client";

export default function Error() {
  return (
    <>
      <div
        className="absolute top-0 -z-50 h-screen w-full"
        style={{
          background: `radial-gradient(80% 50% at 50% 0%, rgba(96, 165, 250, 0.3), rgba(6, 6, 6, 0))`,
        }}
      />
      <div className="bg-background-primary flex h-full grow flex-col items-center justify-center">
        <div className="flex flex-row items-center">
          <p className="mr-6 border-r pr-6 text-3xl font-extrabold">404</p>
          <p className="text-lg font-medium">
            {"La page demandée n'existe pas"}
          </p>
        </div>
      </div>
    </>
  );
}
